<template>
  <div>
    <div v-for="i in 4" :key="'sound-'+i" style="margin-bottom: 20px; width: 80%; margin-left: auto; margin-right: auto;">
      <vue-sound
        title="Judul #1"
        title-link="https://bislin.com/"
        details="Lorem Ipsum Dolor Sit Amet #2"
        details-link="https://bislin.com/"
        file="https://www.hochmuth.com/mp3/Haydn_Cello_Concerto_D-1.mp3"
        :colid="'sound'+i"
      />
    </div>
  </div>
</template>

<script>
import VueSound from '@/components/VueSound.vue'

export default {
  name: 'Sound',
  components: {
    VueSound
  }
}
</script>